import { useSelector } from "react-redux";
import { selectSolution } from "./solutionSlice";
import GuessInput from "../guesses/GuessInput";
import homeIcon from "../../icons/home.svg";
import { Link } from "react-router-dom";
import { selectLetters } from "../letters/lettersSlice";

const Solution = () => {
  const solution = useSelector(selectSolution);
  const letters = useSelector(selectLetters);

  function get_n() {
    if (letters == 0) {
      return 0;
    }

    var title = solution["title"];
    title = atob(title[0]);
    if (letters >= title.length) {
      return title.length;
    }
    var x = letters;
    var front_half = title.slice(0, x);
    var spaces = front_half.replace(" ", "").length;
    return letters + (front_half.length - spaces);
  }

  function solution_start() {
    var title = solution["title"];
    title = atob(title[0]);
    return title.slice(0, get_n());
  }

  return (
    <div className="solutionContainer">
      <div className="initialsBox">
        <div className="solution_start">{solution_start()}</div>
      </div>
      <div className="solutionBox">
        <div>
          <GuessInput />
        </div>
        <div className="homeIconBox">
          <Link to="/">
            <img className="homeIcon" src={homeIcon} alt="Home Icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Solution;
