import { createSlice } from "@reduxjs/toolkit";

const localHints = JSON.parse(localStorage.getItem("hints"));

var initialState;
if (localHints != null) {
  initialState = localHints;
} else {
  initialState = [];
}

const hintsSlice = createSlice({
  name: "hints",
  initialState,
  reducers: {
    hintsAdd: {
      reducer(state, action) {
        state.push(action.payload);
        localStorage.setItem("hints", JSON.stringify(state));
      },
      prepare(hint, center, radius) {
        return {
          payload: {
            hint,
            center,
            radius,
          },
        };
      },
    },
    hintsUpdate: (state, action) => {
      return action.payload;
    },
    hintsReset: () => [],
  },
});

export const selectAllHints = (state) => state.hints;

export const { hintsAdd, hintsReset, hintsUpdate } = hintsSlice.actions;

export default hintsSlice.reducer;
