import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSolution, solutionComplete } from "../solution/solutionSlice";
import { guessesAdd } from "./guessesSlice";
import { hintsAdd, selectAllHints, hintsUpdate } from "../hints/hintsSlice";
import stringSimilarity from "./stringSimilarity";
import Popup from "reactjs-popup";
import { generate_hint } from "../hints/hintsFunctions";
import { curtainStatus } from "../curtain/curtainSlice";
import { selectLetters, lettersAdd } from "../letters/lettersSlice";
import { useNavigate } from "react-router-dom";

const GuessInput = () => {
  const dispatch = useDispatch();
  const solution = useSelector(selectSolution);
  const hints = useSelector(selectAllHints);
  const letters = useSelector(selectLetters);
  const navigate = useNavigate();
  const [guess, setGuess] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupClassName, setPopupClassName] = useState("");
  const [popupText, setPopupText] = useState("");
  const [popupURL, setPopupURL] = useState("");

  const changeGuess = (e) => {
    setGuess(e.target.value);
  };

  function add_hint() {
    var center = null;
    var radius = 30;
    var main_text = solution["main_text"];

    var hint = generate_hint(main_text, center, radius, hints);

    dispatch(hintsAdd(hint.hint, hint.center, hint.radius));
  }

  // function hints_expand(expand) {
  //   var main_text = solution["main_text"];
  //   let hint_list = [];

  //   hints.forEach((hint) => {
  //     let new_hint = generate_hint(
  //       main_text,
  //       hint.center,
  //       hint.radius + expand,
  //       hints
  //     );
  //     hint_list.push({
  //       hint: new_hint.hint,
  //       center: new_hint.center,
  //       radius: new_hint.radius + expand,
  //     });
  //   });
  //   dispatch(hintsUpdate(hint_list));
  // }

  const submitGuess = (e) => {
    if (document.getElementById("guessInput").value == "") {
      return;
    }
    document.activeElement.blur();
    dispatch(guessesAdd(guess));
    setGuess("");
    var url = solution["url"];
    url = atob(url);
    var titles = solution["title"];
    titles.some((title) => {
      var t = atob(title);
      if (guess && !solution["complete"]) {
        var similarFactor = 0;

        similarFactor = stringSimilarity(
          guess.toUpperCase().trim(),
          t.toUpperCase()
        );

        function win_function(text) {
          setPopupOpen(true);
          setPopupClassName("popupGood");
          dispatch(lettersAdd({ n: 999 }));
          setPopupText(text);
          setPopupURL(url);
          dispatch(solutionComplete());
        }

        if (similarFactor > 0.85) {
          win_function("Correct-A-Mundo!");
          return true;
        }
      }

      setPopupOpen(true);
      setPopupClassName("popupBad");
      setPopupText("Sorry, it's not " + guess.trim() + ".");
      setPopupURL("");
      return false;
    });

    e.preventDefault();
  };

  const triggerCurtain = (time) => {
    dispatch(curtainStatus("add"));
    setTimeout(() => {
      dispatch(curtainStatus("remove"));
      setTimeout(() => {
        dispatch(curtainStatus(false));
      }, time / 2);
    }, time);

    setTimeout(() => {
      //hints_expand(5);
      add_hint();
      dispatch(lettersAdd({ n: letters + 1 }));
    }, time);
  };

  const urlDiv = () => {
    var url = popupURL;
    if (url != "") {
      return (
        <div>
          <a href={url} target="_blank">
            {url}
          </a>
          <br />
        </div>
      );
    }
  };

  return (
    <div className="guessInputContainer">
      <form className="guessInputBox" onSubmit={submitGuess}>
        <div className="inputWithSubmit">
          <button>GUESS</button>
          <input
            name="guessInput"
            id="guessInput"
            autoComplete="off"
            type="text"
            value={guess}
            onChange={changeGuess}
          />
        </div>
      </form>
      <Popup
        open={popupOpen}
        onClose={() => {
          setPopupOpen(false);
          triggerCurtain(1000);
          if (solution["complete"] == true) {
            setTimeout(() => {
              navigate("/");
            }, 500);
          }
        }}
      >
        {(close) => (
          <div className="popup">
            <div className={popupClassName}>
              {popupText}
              <br />
              {urlDiv()}
              <button
                onClick={() => {
                  close();
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default GuessInput;
