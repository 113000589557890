import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurtain } from "../curtain/curtainSlice";

const Hint = (props) => {
  const curtain = useSelector(selectCurtain);
  const [guessClass, setGuessClass] = useState("guess");
  const [resetGuessClass, setResetGuessClass] = useState(false);

  function toggle_size(e) {
    if (guessClass == "guess") {
      setGuessClass("guess_enlarge");
    } else {
      setGuessClass("guess");
    }
  }

  if (curtain == "remove" && resetGuessClass == true) {
    setResetGuessClass(false);
    setGuessClass("guess");
  }
  if (curtain == "add" && resetGuessClass == false) {
    setResetGuessClass(true);
  }

  return (
    <div
      index={props.index}
      className={guessClass}
      onClick={(e) => toggle_size(e)}
    >
      <p>
        {props.number + 1}) . . . {props.hint} . . .
      </p>
    </div>
  );
};

export default Hint;
