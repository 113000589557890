import Solution from "../features/solution/Solution";
import Hints from "../features/hints/Hints";

const GamePage = () => {
  return (
    <div className="gamePageBox">
      <Solution />
      <div className="landscape_hidden">
        <div className="hintsGuessesPictureContainer">
          <div className="hintsAndGuessesContainer">
            <Hints hint_numbers={[0, 2, 4]} />
          </div>
          <div className="hintsAndGuessesContainer">
            <Hints hint_numbers={[1, 3, 5]} />
          </div>
        </div>
      </div>
      <div className="portrait_hidden">
        <div className="hintsGuessesPictureContainer">
          <div className="hintsAndGuessesContainer">
            <Hints hint_numbers={[0, 1, 2, 3, 4, 5]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePage;
