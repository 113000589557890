import { createSlice } from "@reduxjs/toolkit";

const localGuesses = JSON.parse(localStorage.getItem("guesses"));

var initialState;
if (localGuesses != null) {
  initialState = localGuesses;
} else {
  initialState = [];
}

const guessesSlice = createSlice({
  name: "guesses",
  initialState,
  reducers: {
    guessesAdd: {
      reducer(state, action) {
        state.push(action.payload);
        localStorage.setItem("guesses", JSON.stringify(state));
      },
      prepare(guess) {
        return {
          payload: guess,
        };
      },
    },
    guessesReset: () => [],
  },
});

export const selectAllGuesses = (state) => state.guesses;

export const { guessesAdd, guessesReset } = guessesSlice.actions;

export default guessesSlice.reducer;
