import React from "react";
import { useSelector } from "react-redux";
import { selectCurtain } from "./curtainSlice";

const Curtain = () => {
  const curtain_status = useSelector(selectCurtain);
  var curtain_class = "curtain_";

  if (curtain_status != false) {
    curtain_class = curtain_class + curtain_status;
    return <div className={curtain_class}></div>;
  } else {
    return;
  }
};

export default Curtain;
