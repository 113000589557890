import { useSelector, useDispatch } from "react-redux";
import {
  solutionReset,
  selectSolution,
  solutionSetToday,
} from "../solution/solutionSlice";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { useState } from "react";
import { guessesReset } from "../guesses/guessesSlice";
import { cursorAuto, cursorWait } from "../basic/cursorSlice";
import { hintsReset, hintsAdd, selectAllHints } from "../hints/hintsSlice";
import { generate_hint } from "../hints/hintsFunctions";
import { lettersReset } from "../letters/lettersSlice";
import { curtainStatus } from "../curtain/curtainSlice";

const PlayButton = (props) => {
  const [openContinuePopup, setOpenContinuePopup] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const solution = useSelector(selectSolution);
  const hints = useSelector(selectAllHints);
  const game = props.game;

  function resetGame() {
    dispatch(solutionReset());
    dispatch(hintsReset());
    dispatch(guessesReset());
    dispatch(lettersReset());
  }

  function add_hint(data) {
    var center = null;
    var radius = 30;
    var main_text = data["main_text"];

    let hint = generate_hint(main_text, center, radius, hints);

    dispatch(hintsAdd(hint.hint, hint.center, hint.radius));
  }

  async function playToday() {
    resetGame();
    dispatch(cursorWait());
    var data = await fetchSolution(game);
    dispatch(solutionSetToday({ payload: data }));

    add_hint(data);
    dispatch(cursorAuto());
    navigate("/game");
  }

  async function fetchSolution(game) {
    var solution;
    var url = window.location.hostname;

    if (url.toLowerCase().includes("localhost")) {
      url = "http://localhost:5000/" + game;
    } else if (url.includes("192.168")) {
      url = "http://192.168.1.107:5000/" + game;
    } else {
      url = "https://whoogabooga.com/server/" + game;
    }

    dispatch(curtainStatus("add"));
    await fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((text) => {
        solution = text;
      })
      .catch(() => {
        solution = null;
      });

    dispatch(curtainStatus("remove"));
    setTimeout(() => {
      dispatch(curtainStatus(false));
    }, 1000);

    return solution;
  }

  return (
    <div className="homePageDivisorContainer">
      <button onClick={() => playToday()}>{props.title}</button>
      <Popup
        open={openContinuePopup}
        onClose={() => setOpenContinuePopup(false)}
      >
        {(close) => (
          <div className="popup">
            <div className="popupGood">
              You never finished your last game. Do you want to resume your last
              game or start today's?
              <br />
              <button
                onClick={() => {
                  close();
                  setOpenContinuePopup(false);
                  navigate("/game");
                }}
              >
                Resume
              </button>
              <button
                onClick={() => {
                  close();
                  setOpenContinuePopup(false);
                  navigate("/game");
                }}
              >
                Today
              </button>
              <button
                onClick={() => {
                  close();
                  setOpenContinuePopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
      <Popup
        open={openErrorPopup}
        onClose={() => setOpenErrorPopup(false)}
        modal
      >
        {(close) => (
          <div className="popup">
            <div className="popupBad">
              <div>Sorry, today's game is unavailable at the moment.</div>
              <div>
                <button
                  onClick={() => {
                    close();
                    setOpenErrorPopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default PlayButton;
