const Copyright = () => {
  const year = new Date().getFullYear();
  return (
    <div className="copyrightDivisorContainer">
      <div className="copyright">
        <div>&copy;{year} Michael Fehling</div>
      </div>
    </div>
  );
};

export default Copyright;
