import { createSlice } from "@reduxjs/toolkit";

var initialState = "";

const curtainSlice = createSlice({
  name: "curtain",
  initialState,
  reducers: {
    curtainStatus: {
      reducer(state, action) {
        return action.payload;
      },
      prepare(status) {
        return {
          payload: status,
        };
      },
    },
  },
});

export const selectCurtain = (state) => state.curtain;

export const { curtainStatus } = curtainSlice.actions;

export default curtainSlice.reducer;
