import { createSlice } from "@reduxjs/toolkit";

const initialState = "auto";

const cursorSlice = createSlice({
  name: "cursor",
  initialState,
  reducers: {
    cursorAuto: () => {
      var root = document.getElementsByTagName("html")[0];
      root.classList.remove("cursorWait");
      return "auto";
    },
    cursorWait: () => {
      var root = document.getElementsByTagName("html")[0];
      root.classList.add("cursorWait");
      return "wait";
    },
  },
});

export const selectCursor = (state) => state.cursor;

export const { cursorAuto, cursorWait } = cursorSlice.actions;

export default cursorSlice.reducer;
