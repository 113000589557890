import { configureStore } from "@reduxjs/toolkit";
import solutionReducer from "../features/solution/solutionSlice";
import guessesReducer from "../features/guesses/guessesSlice";
import userReducer from "../features/user/usersSlice";
import cursorReducer from "../features/basic/cursorSlice";
import hintsReducer from "../features/hints/hintsSlice";
import curtainReducer from "../features/curtain/curtainSlice";
import lettersReducer from "../features/letters/lettersSlice";

export const store = configureStore({
  reducer: {
    solution: solutionReducer,
    guesses: guessesReducer,
    user: userReducer,
    cursor: cursorReducer,
    hints: hintsReducer,
    curtain: curtainReducer,
    letters: lettersReducer,
  },
});
