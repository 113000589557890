import { createSlice } from "@reduxjs/toolkit";

var initialState = JSON.parse(localStorage.getItem("solution"));

const solutionsSlice = createSlice({
  name: "solution",
  initialState,
  reducers: {
    solutionReset: () => null,
    solutionSetToday: (state, action) => {
      var { payload } = action.payload;
      localStorage.setItem("solution", JSON.stringify(payload));
      state = payload;
      return payload;
    },
    solutionComplete: (state) => {
      state["complete"] = true;
      localStorage.setItem("solution", JSON.stringify(state));
    },
  },
});

export const selectSolution = (state) => state.solution;

export const { solutionReset, solutionSetToday, solutionComplete } =
  solutionsSlice.actions;

export default solutionsSlice.reducer;
