import { createSlice } from "@reduxjs/toolkit";

var initialState = 1;

const lettersSlice = createSlice({
  name: "letters",
  initialState,
  reducers: {
    lettersAdd: (state, action) => {
      const { n } = action.payload;
      localStorage.setItem("letters", n);
      return n;
    },
    lettersReset: () => 1,
  },
});

export const selectLetters = (state) => state.letters;

export const { lettersAdd, lettersReset } = lettersSlice.actions;

export default lettersSlice.reducer;
