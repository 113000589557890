const HIDDEN_LETTERS = ["_"];

export function generate_hint(main_text, center, radius, hints) {
  if (center == null) {
    center = seed_hint(main_text, hints, radius, 0);
  }
  if (center + 2 * radius > main_text.length) {
    return { hint: main_text, center: center, radius: radius };
  } else if (center + radius > main_text.length) {
    center = main_text.length - radius;
  } else if (center - radius < 0) {
    center = radius;
  }

  var exp = expand_to_full_words(
    main_text,
    center - radius,
    center + radius * 2
  );
  var start = exp["start"];
  var end = exp["end"];
  return { hint: main_text.slice(start, end), center: center, radius: radius };
}

function seed_hint(main_text, hints, radius, count) {
  if (radius * 2 > main_text.length) {
    return 0;
  }
  var num =
    Math.floor(Math.random() * (main_text.length - 2 * radius + 1)) + radius;
  if (count > 8) {
    return num;
  }
  var duplicate = false;
  hints.forEach((hint) => {
    if (num > hint.center - hint.radius && num < hint.center + hint.radius)
      duplicate = true;
  });

  if (duplicate == true) {
    return seed_hint(main_text, hints, radius, count + 1);
  } else {
    return num;
  }
}

function isLetter(c) {
  return HIDDEN_LETTERS.includes(c) || c.toLowerCase() != c.toUpperCase();
}

function expand_to_full_words(main_text, start, end) {
  var i = start;
  var x = main_text.charAt(i);
  var y = main_text.charAt(i - 1);
  while (i > 0 && isLetter(x) && isLetter(y)) {
    i += -1;
    x = main_text.charAt(i);
    y = main_text.charAt(i - 1);
  }
  start = i;

  i = end;
  x = main_text.charAt(i - 1);
  y = main_text.charAt(i);
  while (i < main_text.length && isLetter(x) && isLetter(y)) {
    i += 1;
    x = main_text.charAt(i - 1);
    y = main_text.charAt(i);
  }
  end = i;

  return { start: start, end: end };
}
