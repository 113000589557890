import { selectAllHints } from "./hintsSlice";
import { useSelector } from "react-redux";
import Hint from "./Hint";

const Hints = (props) => {
  const hints = useSelector(selectAllHints);
  var hints_dict = hints.slice();
  var new_dict = [];

  props.hint_numbers.forEach((n) => {
    if (hints_dict.length > n) {
      new_dict.push([n, hints_dict[n]]);
    }
  });

  const renderedhints = new_dict.map((h, i) => (
    <Hint key={i} number={h[0]} hint={h[1]["hint"]} />
  ));

  return (
    <div className="guessContainer">
      <div className="guessContainerBox">
        <div className="guessBox">{renderedhints}</div>
      </div>
    </div>
  );
};

export default Hints;
