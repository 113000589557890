import { createSlice } from "@reduxjs/toolkit";
import { current } from "@reduxjs/toolkit";

var initialState = JSON.parse(localStorage.getItem("user"));

if (initialState == null) {
  initialState = [];
}

function checkDuplicate(user, payload) {
  var duplicate = false;
  if (payload["date"] == null) {
    duplicate = true;
  }
  user.forEach((item) => {
    if (item["date"] == payload["date"]) {
      duplicate = true;
      return;
    }
  });
  return duplicate;
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logScore: {
      reducer(state, action) {
        var duplicateEntry = checkDuplicate(current(state), action.payload);
        if (duplicateEntry == false) {
          state.push(action.payload);
          localStorage.setItem("user", JSON.stringify(state));
        }
      },
      prepare(date, solutionName, points) {
        return {
          payload: {
            date,
            solutionName,
            points,
          },
        };
      },
    },
  },
});

export const selectUser = (state) => state.user;

export const { logScore } = userSlice.actions;

export default userSlice.reducer;
