import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Curtain from "./features/curtain/Curtain";
import GamePage from "./pages/GamePage";
import HomePage from "./pages/HomePage";

function App() {
  return (
    <>
      <Curtain />
      <Router>
        <Routes>
          <Route exact path="/game" element={<GamePage />}></Route>
          <Route exact path="/" element={<HomePage />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
