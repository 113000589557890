import PlayButton from "../features/HomePage/PlayButton";
import Copyright from "../features/basic/Copyright";

const HomePage = () => {
  return (
    <div className="homePageBox">
      <div className="homePageTitle">
        <h1>WhoogaBooga</h1>
      </div>
      <div className="homePageSubTitle">
        <h2>Guess the Wikipedia Title</h2>
      </div>

      <PlayButton game={"general"} title={"General Words"} />
      <PlayButton game={"character_fiction"} title={"Fictional Characters"} />
      <PlayButton game={"animal_simple"} title={"Kids Animals"} />

      <Copyright />
    </div>
  );
};

export default HomePage;
